<template>
  <div class="container bg-light px-5">
    <div class="row">
      <h1>Unsere Lieblichkeiten</h1>
      <div
        id="carouselExampleInterval"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            v-for="image in this.images"
            :key="image"
            :class="image.className"
          >
            <img
              :src="image.pathLong"
              class="d-block carousel-item-end mx-auto"
              alt=""
            />
          </div>
        </div>
        <button
          class="carousel-control-prev text-dark bg-dark"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next bg-dark"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useActiveMeta, useMeta } from "vue-meta";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrincessView",

  data: () => ({
    images: [],
  }),

  mounted() {
    this.importAll(
      require.context("../assets/images/Lieblichkeiten", true, /\.png$/)
    );
  },

  setup() {
    useMeta({
      title: "Galerie unserer Prinzessinnen - Die Regentinnen der Großen 3",
      og: {
        title: "Galerie unserer Prinzessinnen - Die Regentinnen der Großen 3",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },

  methods: {
    importAll(r) {
      r.keys().forEach((key) => {
        this.images.push({ pathLong: r(key), className: "" });
      });
      this.images[this.images.length - 1].className = "active ";
      this.images.reverse();
    },
  },
});
</script>

<style scoped></style>
