<template>
  <div class="container bg-light px-5">
    <h1>Termine</h1>
    <h2>Unsere Kampagnentermine 2024 - 2025</h2>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <td>16.11.2024</td>
                <td>Inthronisation</td>
                <td>Gaststätte Schrebergärtenverein Viernheim</td>
              </tr>
              <tr>
                <td>07.12.2024</td>
                <td>Weihnachtsfeier</td>
                <td>Pfadfinderheim</td>
              </tr>
              <tr>
                <td>13 – 15.12.2024</td>
                <td>Weihnachtsmarkt</td>
                <td>Viernheimer Innenstadt</td>
              </tr>
              <tr>
                <td>12.01.2025</td>
                <td>Stadtempfang + Rittercafe</td>
                <td></td>
              </tr>
              <tr>
                <td>18.01.2025</td>
                <td>Spektakel</td>
                <td></td>
              </tr>
              <tr>
                <td>15.02.2025</td>
                <td>Prunksitzung</td>
                <td>Bürgerhaus</td>
              </tr>
              <tr>
                <td>01.03.2025</td>
                <td>Fastnachtssamstag</td>
                <td></td>
              </tr>
              <tr>
                <td>02.03.2025</td>
                <td>Straßenfastnacht Viernheim</td>
                <td></td>
              </tr>
              <tr>
                <td>04.03.2025</td>
                <td>Umzug Lorsch</td>
                <td></td>
              </tr>
              <tr>
                <td>05.03.2025</td>
                <td>Kampagnenabschluss</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12">
        <h3>Der Terminflyer der Großen 3 Viernheim</h3>
        <img
          src="/images/termine-2024-2025_500x701.jpg"
          alt="Terminflyer der Großen 3 Viernheim, der die oben genannten Termine widerspiegelt"
          class="img-fluid mx-auto d-block"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Kampagnentermine - Übersicht unserer Veranstaltungen",
      og: {
        title: "Kampagnentermine - Übersicht unserer Veranstaltungen",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
