<template>
  <div class="container bg-light px-5">
    <h1>Downloads</h1>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Beitrittserklarung.pdf"
                target="_blank"
              >
                Vereins Beitrittserklärung
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Personenfuersorge.pdf"
                target="_blank"
              >
                Erziehungsbeauftragung gemäß Jugendschutzgesetz ("Mutti-Zettel")
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2011.pdf"
                target="_blank"
              >
                Kampagnenheft 2011
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2012.pdf"
                target="_blank"
              >
                Kampagnenheft 2012
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2013.pdf"
                target="_blank"
              >
                Kampagnenheft 2013
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2014.pdf"
                target="_blank"
              >
                Kampagnenheft 2014
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2015.pdf"
                target="_blank"
              >
                Kampagnenheft 2015
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2016.pdf"
                target="_blank"
              >
                Kampagnenheft 2016
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2017.pdf"
                target="_blank"
              >
                Kampagnenheft 2017
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2018.pdf"
                target="_blank"
              >
                Kampagnenheft 2018
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2019.pdf"
                target="_blank"
              >
                Kampagnenheft 2019
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2021.pdf"
                target="_blank"
              >
                Kampagnenheft 2021
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Einleger2022.pdf"
                target="_blank"
              >
                Einleger 2022
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2023.pdf"
                target="_blank"
              >
                Kampagnenheft 2023
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://downloads.grosse3.de/Kampagnenheft2024.pdf"
                target="_blank"
              >
                Kampagnenheft 2024
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Downloads - Kampagnenhefte, Betrittserklärung, Personenfürsorge",
      og: {
        title:
          "Downloads - Kampagnenhefte, Betrittserklärung, Personenfürsorge",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
